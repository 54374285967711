export function buildUrlParams(params) {
  const strParams = Object.entries(params)
    .map(([name, value]) => {
      return value ? `${name}=${encodeURIComponent(value)}` : '';
    })
    .filter((param) => !!param)
    .join('&');

  return strParams ? `?${strParams}` : '';
}

export async function getResponseBody(response) {
  const text = await response.text();
  return JSON.parse(text || '{}');
}
