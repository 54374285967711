import React, { Fragment } from 'react';
import Scrollbar from '../../components/scrollbar';
import ClientForm from '../../components/ClientForm';

const ContactPage = () => {
  return (
    <Fragment>
      <ClientForm />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
