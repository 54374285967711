import React from 'react';
import ContactForm from '../ContactFrom';

const ClientForm = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="wpo-contact-title">
            <h2>Necesimos algunos detalles mas antes de contactarte</h2>
            {/* <p>¡Escríbenos!</p> */}
          </div>
          <div className="wpo-contact-form-area">
            <ContactForm />
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map"></div>
      </section>
    </section>
  );
};

export default ClientForm;
