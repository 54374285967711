import React from 'react';

import cnt1 from '../../images/icon/home.svg';
import cnt2 from '../../images/icon/mail-2.svg';
import cnt3 from '../../images/icon/app.svg';
import ContactForm from '../ContactFrom';

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="wpo-contact-title">
            <h2>¿Estás interesado en nuestros servicios?</h2>
            {/* <p>¡Escríbenos!</p> */}
          </div>
          <div className="wpo-contact-form-area">
            <ContactForm />
          </div>
          <div className="col col-lg-10 offset-lg-1 mt-5">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt1} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Ubicación</h2>
                      <p>Caracas, Venezuela</p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt2} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Correo</h2>
                      <p>
                        <a href="mailto:contacto@kesildigital.com">contacto@kesildigital.com</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt3} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>WhatsApp</h2>
                      <p>
                        <a href="https://wa.me/584242698861">+58 424-2698861</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map"></div>
      </section>
    </section>
  );
};

export default Contactpage;
