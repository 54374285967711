import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import About from '../../components/about';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import aboutBg from '../../images/about-header.webp';

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Conócenos'} pagesub={'Nosotros'} bgImage={aboutBg} />
      <About abClass={'wpo-about-section-s2'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
