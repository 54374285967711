import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import DataPolicyContent from '../../components/DataPolicyPage';

const DataPolicyPage = () => {
  return (
    <Fragment>
      <Navbar2 alwaysFixed />
      <DataPolicyContent />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DataPolicyPage;
