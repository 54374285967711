import React from 'react';
// import { Link } from 'react-router-dom';

const HeaderTopbar = () => {
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-7 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <i className="fi ti-location-pin"></i>Caracas, Venezuela
                </li>
                <li>
                  <i className="fi flaticon-email"></i> contacto@kesildigital.com
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col col-md-5 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                <li>Visita nuestras redes sociales</li>
                <li>
                  <Link to="/">
                    <i className="ti-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="ti-twitter-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="ti-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
