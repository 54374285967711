import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Contactpage from '../../components/Contactpage';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar2 alwaysFixed />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
