import React from 'react';

import prdimg1 from '../../images/icon/document.svg';
import prdimg2 from '../../images/icon/bar-graph.svg';
import prdimg3 from '../../images/icon/clipboard.svg';

const Features = (props) => {
  const featres = [
    {
      fIcon: prdimg1,
      title: 'Metodologías Ágiles',
      des: 'Nos adaptamos rápidamente a tus necesidades y cambios en el proyecto para entregar resultados óptimos en tiempo y forma.',
    },
    {
      fIcon: prdimg2,
      title: 'Experiencia',
      des: 'Más de 7 años desarrollando software exitoso para diversas industrias y tipos de negocios.',
    },
    {
      fIcon: prdimg3,
      title: 'Atención Personalizada',
      des: 'Te mantenemos informado y participativo durante todo el proceso, asegurando que el resultado final cumpla con tus expectativas.',
    },
  ];

  return (
    <section className={`wpo-features-section section-padding  ${props.featuresClass}`}>
      <div className="container">
        <div className="row">
          {featres.map((featres, fitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={fitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={featres.fIcon} alt="" />
                  </div>
                </div>
                <div className="wpo-features-text pt-3">
                  <h3>{featres.title}</h3>
                  <p>{featres.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
