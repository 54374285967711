import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Hero3 from '../../components/hero3';
import Features from '../../components/Features';
import About from '../../components/about';

import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const HomePage3 = () => {
  return (
    <Fragment>
      <Navbar2 />
      <Hero3 />
      <Features featuresClass="wpo-features-section-s2" />
      <About />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage3;
