import { getApiURL } from '../utils/env';
import { buildUrlParams, getResponseBody } from '../utils/requestsHelpers';

const defaultConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

function useRequests() {
  const apiURL = getApiURL();
  async function get(endpoint, params = {}) {
    const urlParams = buildUrlParams(params);
    const response = await fetch(`${apiURL}${endpoint}${urlParams}`, defaultConfig);
    const { status } = response;
    const data = await getResponseBody(response);

    return { status, data };
  }

  async function post(endpoint, bodyObject = {}, customHeaders = {}) {
    const body = bodyObject instanceof FormData ? bodyObject : JSON.stringify(bodyObject);
    const { credentials, headers: defaultHeaders } = defaultConfig;
    const headers = bodyObject instanceof FormData ? {} : { ...customHeaders, ...defaultHeaders };
    const response = await fetch(`${apiURL}${endpoint}`, {
      credentials,
      headers,
      method: 'POST',
      body,
    });
    const { status } = response;
    const data = await getResponseBody(response);

    return { status, data };
  }

  return { get, post };
}

export default useRequests;
