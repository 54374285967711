import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = (props) => {
  return (
    <div className="wpo-breadcumb-area" style={{ backgroundImage: `url(${props.bgImage})` }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-breadcumb-wrap">
              <h2>{props.pageTitle}</h2>
              <ul>
                {!props.noTitle && (
                  <>
                    <li>
                      <Link to="/home">Inicio</Link>
                    </li>
                    <li>
                      <span>{props.pagesub}</span>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
