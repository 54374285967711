import React from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hero1 from '../../images/slider/hero2.webp';
import { useTranslation } from 'react-i18next';

function Hero3() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  function contact() {
    navigate('/contact');
  }

  return (
    <section className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide" onClick={contact}>
              <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h1 dangerouslySetInnerHTML={{ __html: t('welcome') }} />
                    </div>
                    <div className="slide-text">
                      <p>{t('description')}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="/contact" className="theme-btn">
                        Contáctanos
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Hero3;
