// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traducciones
const resources = {
  en: {
    translation: {
      welcome: 'Welcome to <br> Kesil Digital',
      description: 'Customized software development.',
    },
  },
  es: {
    translation: {
      welcome: 'Bienvenido a <br/> Kesil Digital',
      description: 'Desarrollo de software a la medida.',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es', // Idioma por defecto
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false, // React ya escapa los valores por defecto
  },
});

export default i18n;
