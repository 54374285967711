export const LOCAL_API_URL = 'http://127.0.0.1:5001/kesil-digital-landing-page/us-central1/apiRestServer';

export const PROD_API_URL = 'https://apirestserver-ihtwhxjygq-uc.a.run.app';

export function getApiURL() {
  if (window.location.origin.includes('localhost')) {
    return LOCAL_API_URL;
  }

  return PROD_API_URL;
}
