const phoneCodes = [
  { country: 'Afganistán', code: '+93' },
  { country: 'Albania', code: '+355' },
  { country: 'Alemania', code: '+49' },
  { country: 'Andorra', code: '+376' },
  { country: 'Angola', code: '+244' },
  { country: 'Antigua y Barbuda', code: '+1-268' },
  { country: 'Arabia Saudita', code: '+966' },
  { country: 'Argelia', code: '+213' },
  { country: 'Argentina', code: '+54' },
  { country: 'Armenia', code: '+374' },
  { country: 'Australia', code: '+61' },
  { country: 'Austria', code: '+43' },
  { country: 'Azerbaiyán', code: '+994' },
  { country: 'Bahamas', code: '+1-242' },
  { country: 'Bangladés', code: '+880' },
  { country: 'Barbados', code: '+1-246' },
  { country: 'Baréin', code: '+973' },
  { country: 'Bélgica', code: '+32' },
  { country: 'Belice', code: '+501' },
  { country: 'Benín', code: '+229' },
  { country: 'Bielorrusia', code: '+375' },
  { country: 'Birmania', code: '+95' },
  { country: 'Bolivia', code: '+591' },
  { country: 'Bosnia y Herzegovina', code: '+387' },
  { country: 'Botsuana', code: '+267' },
  { country: 'Brasil', code: '+55' },
  { country: 'Brunéi', code: '+673' },
  { country: 'Bulgaria', code: '+359' },
  { country: 'Burkina Faso', code: '+226' },
  { country: 'Burundi', code: '+257' },
  { country: 'Bután', code: '+975' },
  { country: 'Cabo Verde', code: '+238' },
  { country: 'Camboya', code: '+855' },
  { country: 'Camerún', code: '+237' },
  { country: 'Canadá', code: '+1' },
  { country: 'Catar', code: '+974' },
  { country: 'Chad', code: '+235' },
  { country: 'Chile', code: '+56' },
  { country: 'China', code: '+86' },
  { country: 'Chipre', code: '+357' },
  { country: 'Colombia', code: '+57' },
  { country: 'Comoras', code: '+269' },
  { country: 'Congo', code: '+242' },
  { country: 'Corea del Norte', code: '+850' },
  { country: 'Corea del Sur', code: '+82' },
  { country: 'Costa de Marfil', code: '+225' },
  { country: 'Costa Rica', code: '+506' },
  { country: 'Croacia', code: '+385' },
  { country: 'Cuba', code: '+53' },
  { country: 'Dinamarca', code: '+45' },
  { country: 'Dominica', code: '+1-767' },
  { country: 'Ecuador', code: '+593' },
  { country: 'Egipto', code: '+20' },
  { country: 'El Salvador', code: '+503' },
  { country: 'Emiratos Árabes Unidos', code: '+971' },
  { country: 'Eritrea', code: '+291' },
  { country: 'Eslovaquia', code: '+421' },
  { country: 'Eslovenia', code: '+386' },
  { country: 'España', code: '+34' },
  { country: 'Estados Unidos', code: '+1' },
  { country: 'Estonia', code: '+372' },
  { country: 'Esuatini', code: '+268' },
  { country: 'Etiopía', code: '+251' },
  { country: 'Filipinas', code: '+63' },
  { country: 'Finlandia', code: '+358' },
  { country: 'Fiyi', code: '+679' },
  { country: 'Francia', code: '+33' },
  { country: 'Gabón', code: '+241' },
  { country: 'Gambia', code: '+220' },
  { country: 'Georgia', code: '+995' },
  { country: 'Ghana', code: '+233' },
  { country: 'Granada', code: '+1-473' },
  { country: 'Grecia', code: '+30' },
  { country: 'Guatemala', code: '+502' },
  { country: 'Guinea', code: '+224' },
  { country: 'Guinea-Bisáu', code: '+245' },
  { country: 'Guinea Ecuatorial', code: '+240' },
  { country: 'Guyana', code: '+592' },
  { country: 'Haití', code: '+509' },
  { country: 'Honduras', code: '+504' },
  { country: 'Hungría', code: '+36' },
  { country: 'India', code: '+91' },
  { country: 'Indonesia', code: '+62' },
  { country: 'Irak', code: '+964' },
  { country: 'Irán', code: '+98' },
  { country: 'Irlanda', code: '+353' },
  { country: 'Islandia', code: '+354' },
  { country: 'Islas Marshall', code: '+692' },
  { country: 'Islas Salomón', code: '+677' },
  { country: 'Israel', code: '+972' },
  { country: 'Italia', code: '+39' },
  { country: 'Jamaica', code: '+1-876' },
  { country: 'Japón', code: '+81' },
  { country: 'Jordania', code: '+962' },
  { country: 'Kazajistán', code: '+7' },
  { country: 'Kenia', code: '+254' },
  { country: 'Kirguistán', code: '+996' },
  { country: 'Kiribati', code: '+686' },
  { country: 'Kuwait', code: '+965' },
  { country: 'Laos', code: '+856' },
  { country: 'Lesoto', code: '+266' },
  { country: 'Letonia', code: '+371' },
  { country: 'Líbano', code: '+961' },
  { country: 'Liberia', code: '+231' },
  { country: 'Libia', code: '+218' },
  { country: 'Liechtenstein', code: '+423' },
  { country: 'Lituania', code: '+370' },
  { country: 'Luxemburgo', code: '+352' },
  { country: 'Madagascar', code: '+261' },
  { country: 'Malasia', code: '+60' },
  { country: 'Malaui', code: '+265' },
  { country: 'Maldivas', code: '+960' },
  { country: 'Malí', code: '+223' },
  { country: 'Malta', code: '+356' },
  { country: 'Marruecos', code: '+212' },
  { country: 'Mauricio', code: '+230' },
  { country: 'Mauritania', code: '+222' },
  { country: 'México', code: '+52' },
  { country: 'Micronesia', code: '+691' },
  { country: 'Moldavia', code: '+373' },
  { country: 'Mónaco', code: '+377' },
  { country: 'Mongolia', code: '+976' },
  { country: 'Montenegro', code: '+382' },
  { country: 'Mozambique', code: '+258' },
  { country: 'Namibia', code: '+264' },
  { country: 'Nauru', code: '+674' },
  { country: 'Nepal', code: '+977' },
  { country: 'Nicaragua', code: '+505' },
  { country: 'Níger', code: '+227' },
  { country: 'Nigeria', code: '+234' },
  { country: 'Noruega', code: '+47' },
  { country: 'Nueva Zelanda', code: '+64' },
  { country: 'Omán', code: '+968' },
  { country: 'Países Bajos', code: '+31' },
  { country: 'Pakistán', code: '+92' },
  { country: 'Palaos', code: '+680' },
  { country: 'Palestina', code: '+970' },
  { country: 'Panamá', code: '+507' },
  { country: 'Papúa Nueva Guinea', code: '+675' },
  { country: 'Paraguay', code: '+595' },
  { country: 'Perú', code: '+51' },
  { country: 'Polonia', code: '+48' },
  { country: 'Portugal', code: '+351' },
  { country: 'Reino Unido', code: '+44' },
  { country: 'República Centroafricana', code: '+236' },
  { country: 'República Checa', code: '+420' },
  { country: 'República Democrática del Congo', code: '+243' },
  { country: 'República Dominicana', code: '+1-809, +1-829, +1-849' },
  { country: 'Ruanda', code: '+250' },
  { country: 'Rumania', code: '+40' },
  { country: 'Rusia', code: '+7' },
  { country: 'Samoa', code: '+685' },
  { country: 'San Cristóbal y Nieves', code: '+1-869' },
  { country: 'San Marino', code: '+378' },
  { country: 'San Vicente y las Granadinas', code: '+1-784' },
  { country: 'Santa Lucía', code: '+1-758' },
  { country: 'Santo Tomé y Príncipe', code: '+239' },
  { country: 'Senegal', code: '+221' },
  { country: 'Serbia', code: '+381' },
  { country: 'Seychelles', code: '+248' },
  { country: 'Sierra Leona', code: '+232' },
  { country: 'Singapur', code: '+65' },
  { country: 'Siria', code: '+963' },
  { country: 'Somalia', code: '+252' },
  { country: 'Sri Lanka', code: '+94' },
  { country: 'Suazilandia', code: '+268' },
  { country: 'Sudáfrica', code: '+27' },
  { country: 'Sudán', code: '+249' },
  { country: 'Sudán del Sur', code: '+211' },
  { country: 'Suecia', code: '+46' },
  { country: 'Suiza', code: '+41' },
  { country: 'Surinam', code: '+597' },
  { country: 'Tailandia', code: '+66' },
  { country: 'Tanzania', code: '+255' },
  { country: 'Tayikistán', code: '+992' },
  { country: 'Timor Oriental', code: '+670' },
  { country: 'Togo', code: '+228' },
  { country: 'Tonga', code: '+676' },
  { country: 'Trinidad y Tobago', code: '+1-868' },
  { country: 'Túnez', code: '+216' },
  { country: 'Turkmenistán', code: '+993' },
  { country: 'Turquía', code: '+90' },
  { country: 'Tuvalu', code: '+688' },
  { country: 'Ucrania', code: '+380' },
  { country: 'Uganda', code: '+256' },
  { country: 'Uruguay', code: '+598' },
  { country: 'Uzbekistán', code: '+998' },
  { country: 'Vanuatu', code: '+678' },
  { country: 'Vaticano', code: '+379' },
  { country: 'Venezuela', code: '+58' },
  { country: 'Vietnam', code: '+84' },
  { country: 'Yemen', code: '+967' },
  { country: 'Yibuti', code: '+253' },
  { country: 'Zambia', code: '+260' },
  { country: 'Zimbabue', code: '+263' },
];

export default phoneCodes;
