import React from 'react';

const ThankyouPage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="wpo-contact-title">
            <h2> ¡Hemos recibido tu mensaje!</h2>
            <p> Ya no debes hacer nada más, nos pondremos en contacto contigo lo más pronto posible.</p>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map"></div>
      </section>
    </section>
  );
};

export default ThankyouPage;
