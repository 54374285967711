import React from 'react';

import srvimg1 from '../../images/icon/clipboard.svg';
import srvimg2 from '../../images/icon/briefcase.svg';
import srvimg3 from '../../images/icon/chart.svg';
import srvimg4 from '../../images/icon/badge.svg';
import srvimg5 from '../../images/icon/goal.svg';
import srvimg6 from '../../images/icon/handshake.svg';

const Services = (props) => {
  const service = [
    {
      sIcon: srvimg1,
      title: 'Desarrollo de Aplicaciones Web Personalizadas',
      des: 'Diseño y desarrollo de aplicaciones web adaptadas a las necesidades específicas de tu negocio.',
    },
    {
      sIcon: srvimg2,
      title: 'Desarrollo de Aplicaciones Móviles',
      des: 'Creación de aplicaciones móviles para iOS y Android, optimizadas para rendimiento y usabilidad.',
    },
    {
      sIcon: srvimg3,
      title: 'Consultoría Tecnológica',
      des: 'Asesoramiento experto para identificar y implementar soluciones tecnológicas que impulsen tu negocio.',
    },
    {
      sIcon: srvimg4,
      title: 'Integración de Sistemas y APIs',
      des: 'Conexión de diferentes sistemas y aplicaciones mediante APIs para mejorar la eficiencia operativa.',
    },
    {
      sIcon: srvimg5,
      title: 'Mantenimiento y Soporte Técnico',
      des: 'Servicios de mantenimiento continuo y soporte técnico para asegurar el funcionamiento óptimo de tus aplicaciones.',
    },
    {
      sIcon: srvimg6,
      title: 'Transformación Digital',
      des: 'Estrategias y soluciones para digitalizar y modernizar los procesos de tu empresa, aumentando la productividad y competitividad.',
    },
  ];

  return (
    <section className="wpo-service-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-section-title">
              <span>Nuestros Servicios</span>
              <h2>Explora Nuestros Servicios</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {service.map((service, sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={sitem}>
              <div className="wpo-service-item" style={{ minHeight: '418px' }}>
                <div className="wpo-service-icon">
                  <div className="icon">
                    <img src={service.sIcon} alt="" />
                  </div>
                </div>
                <div className="wpo-service-text">
                  <h2>{service.title}</h2>
                  <p>{service.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
