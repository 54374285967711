import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import contactBg from '../../images/contact-header.webp';
import ThankyouPage from '../../components/ThankyouPage';

const ThankyouPageMain = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Gracias por contactarnos'} bgImage={contactBg} noTitle />
      <ThankyouPage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ThankyouPageMain;
